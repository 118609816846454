<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <tabel-data :rs_data=rs_data :t_bobot="t_bobot" @UpdatePenilaian="UpdatePenilaian"></tabel-data>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from '@/config/Axios'
import Base from '@/config/Mixins_base'
import {
  BTabs,
  BTab, BRow, BCol, BCard
} from 'bootstrap-vue'
import TabelData from './component/TabelData.vue'
export default {
  components: {
    BTabs,
    BTab, BRow, BCol, BCard,
    TabelData,
  },
  props: {

  },
  data() {
    return {
      rs_data: [],
      t_bobot: 0
    }
  },
  mixins: [Base],
  mounted() {
    this.load_data();
    this.total_bobot();
  },
  methods: {
    async UpdatePenilaian(val) {
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/pu/setup/komponen_nilai/update_bobot',
        data: {
          bobot: val.input,
          id_bobot_penilaian: val.id_bobot_penilaian
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.notification('warning', "Info.....", response.data.message);
          self.load_data();

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async load_data() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/setup/komponen_nilai/load_data',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rs_data = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async total_bobot() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/setup/komponen_nilai/total_bobot',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.t_bobot = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },

  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },

}
</script>

<style></style>